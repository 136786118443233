const BASE_URL = process.env.REACT_APP_BASE_URL;
export async function apiLogin(userName, password) {
  const response = await fetch(`${BASE_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userName, password }),
  });

  // if (!response.ok) {
  //   throw new Error('Login failed');
  // }

  const data = await response.json();
  if(response.status === 400) {
    return data
  }
  return data.payload;
}

export async function apiCourseRegister(name, logo, userForm) {
  const response = await fetch(`${BASE_URL}/course`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name: name, icon: logo }),
  });

  const data = await response.json();

  if (response.ok) {
    const courseId = data.payload.course.id;
    userForm.courseId = courseId;
    const userResponse = await addUser(userForm);
    return userResponse
  } else {
    return data
  }
  // if(response.status === 400) {
    
  // }
}

//-------------USER-------------------
export async function getUsers(page, searchForm) {
  let url = `${BASE_URL}/auth/${page}`;

  const queryParams = [];
  if (searchForm.firstName !== '') queryParams.push(`firstName=${searchForm.firstName}`);
  if (searchForm.lastName !== '') queryParams.push(`lastName=${searchForm.lastName}`);
  if (searchForm.userName !== '') queryParams.push(`userName=${searchForm.userName}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  const token = localStorage.getItem('token')
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get course failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getUsersByCourse(id, page, searchForm) {
  let url = `${BASE_URL}/auth/bycourse/${id}/${page}`;

  const queryParams = [];
  if (searchForm.firstName !== '') queryParams.push(`firstName=${searchForm.firstName}`);
  if (searchForm.lastName !== '') queryParams.push(`lastName=${searchForm.lastName}`);
  if (searchForm.userName !== '') queryParams.push(`userName=${searchForm.userName}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }
  
  const token = localStorage.getItem('token')
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get course failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addUser(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });

  const responseJson = await response.json();
  if(response.status === 400) {
    return responseJson
  }
  else return responseJson.payload;
}

export async function editUser(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/auth/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add course failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteUser(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/auth/delete/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add Course failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function getUserStatus() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/auth/user/status`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    return { isError: true };
  }

  const data = await response.json();
  return data.payload;
}

export async function activateUser(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/auth/activate/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });

  if (!response.ok) {
    throw new Error('Activate user failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deActivateUser(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/auth/deactivate/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });

  if (!response.ok) {
    throw new Error('deactivate user failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function uploadUsersFromExcel(courseId, moduleId, file) {
  const token = localStorage.getItem('token')
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${BASE_URL}/auth/upload-users/${courseId}/${moduleId}`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token
    },
    body: formData
  });

  const responseJson = await response.json();
  if(response.status === 400) {
    return responseJson;
  }
  return responseJson.payload;
}

//-------------LESSON-------------------
export async function getLessons() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lesson`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get lesson failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getLessonsByCategoryId(categoryId) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lesson/c/${categoryId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get lesson failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addLesson(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lesson`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add lesson failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editLesson(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lesson/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add lesson failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteLesson(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lesson/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add lesson failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

//-------------COURSE-------------------
export async function getCourses() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/course`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get course failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addCourse(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/course`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add course failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editCourse(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/course/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add course failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteCourse(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/course/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add Course failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function payCourse(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/course/pay/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add Course failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function getCourseInfo(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/course/info/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get course info failed');
  }

  const data = await response.json();
  return data.payload;
}

//-------------ROLE-------------------
export async function getRoles() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/role`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get course failed');
  }

  const data = await response.json();
  return data.payload;
}

//-------------EXAM-------------------
export async function getExams() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/exam`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get exam failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getExamsByLanguage(lang, module, category) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/exam/e/${lang}/${module}/${category}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get exam failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getLatestExams(lang, module) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/exam/latest/${lang}/${module}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get exam failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addExam(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/exam`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add exam failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editExam(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/exam/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add exam failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteExam(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/exam/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add exam failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

//-------------QUESTION-------------------
export async function getQuestions() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/question`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get question failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getQuestionsbyExam(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/question/qid/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get question failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getPanelQuestionsbyExam(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/question/panel/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get question failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getQuestionsIdsByExam(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/question/qid/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get question failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getQuestionById(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/question/q/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get question failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addQuestion(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/question`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add question failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editQuestion(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/question/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add question failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteQuestion(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/question/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add question failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

//-------------SECTION-------------------
export async function getSections() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/section`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get section failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getSectionsByLanguage(lang, module) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/section/${lang}/${module}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get section failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addSection(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/section`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add section failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editSection(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/section/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add section failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteSection(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/section/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add section failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

//-------------SECTIONPART-------------------
export async function getSectionParts() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/sectionPart`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get sectionPart failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getPartsbySection(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/sectionPart/bysection/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get sectionPart failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getPartsPanel(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/sectionPart/panel/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get sectionPart failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addSectionPart(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/sectionPart`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add sectionPart failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editSectionPart(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/sectionPart/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add sectionPart failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteSectionPart(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/sectionPart/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add sectionPart failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function getSectionPartAudio(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/sectionpart/${id}/audio`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  if(response.status === 400) {
    return response
  }
  
  // if (!response.ok) {
  //   throw new Error('Get sectionPart failed');
  // }

  const data = await response.arrayBuffer();
  const blob = new Blob([data], { type: 'audio/mpeg' });
  const blobURL = URL.createObjectURL(blob);
  return blobURL;
}

export async function getSectionPartImages(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/sectionPart/i/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get sectionPart failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getSectionPartImagesIndex(id, index) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/sectionPart/image/${id}/${index}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get sectionPart failed');
  }

  const data = await response.json();
  return data.payload;
}

//-------------LANGUAGE-------------------
export async function getLanguages() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/language`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get language failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getLanguagesByIds(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/language/byids`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Get language failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function addLanguage(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/language`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add language failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editLanguage(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/language/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add language failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteLanguage(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/language/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add language failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

//-------------MODULES-------------------
export async function getModules() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/module`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get module failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getModulesByIds(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/module/byids`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Get module failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function addModule(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/module`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add module failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editModule(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/module/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add module failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteModule(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/module/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add module failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

//-------------EXAM CATEGORIES-------------------
export async function getExamCates() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/examcategory`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get examcategory failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getExamCatesByLanguage(lang, module) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/examcategory/e/${lang}/${module}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get examcategory failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getLatestExamCates(lang, module) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/examcategory/latest/${lang}/${module}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get examcategory failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addExamCate(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/examcategory`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add examcategory failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editExamCate(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/examcategory/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add examcategory failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteExamCate(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/examcategory/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add examcategory failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

//-------------LESSON CATEGORIES-------------------
export async function getLessonCates() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lessoncategory`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get lessoncategory failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getLessonCatesByLanguage(lang, module) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lessoncategory/e/${lang}/${module}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get lessoncategory failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function getLatestLessonCates(lang, module) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lessoncategory/latest/${lang}/${module}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get lessoncategory failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addLessonCate(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lessoncategory`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add lessoncategory failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editLessonCate(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lessoncategory/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add lessoncategory failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deleteLessonCate(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/lessoncategory/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add lessoncategory failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

//-------------PAYMENT PRICE-------------------
export async function getPaymentPrices() {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/paymentprice`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Get PaymentPrice failed');
  }

  const data = await response.json();
  return data.payload;
}

export async function addPaymentPrice(data) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/paymentprice`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add PaymentPrice failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function editPaymentPrice(data, id) {
  const dataJson = JSON.stringify(data)
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/paymentprice/edit/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
    body: dataJson
  });
  
  if (!response.ok) {
    throw new Error('Add PaymentPrice failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}

export async function deletePaymentPrice(id) {
  const token = localStorage.getItem('token')
  const response = await fetch(`${BASE_URL}/paymentprice/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    },
  });
  
  if (!response.ok) {
    throw new Error('Add PaymentPrice failed');
  }

  const responseJson = await response.json();
  return responseJson.payload;
}